import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useBetSlip } from './BetSlipHooks';
import { GetFootballConfig, GetBetslipConfig } from '../Common/ConfigHelper';
import { textFormatAmountFn } from '../Common/GlobalFunc';
import { fbAlupCalc, fbCalMultiSel } from './BetlinesValidFunc';
import ReactToPrint from 'react-to-print';
import { getPersonalSettings } from '../Home/PersonalSettings/config';
import { WATrackerTrackClickEvent } from '../Common/utils/wa';
import Popup from 'reactjs-popup';
import { glassboxCustomEvent } from '../Common/utils/glassbox';
import { CSSTransition } from 'react-transition-group';
import ArrowIcon from '../Common/ui-components/ArrowIcon';
import { Cookies } from '../Common/CookieHelper';
import { formatNumber } from '../Common/GlobalFunc';
import { useWindowSize } from '../Common/CommonHooks';

export const ConfirmationHeader = ({ confirmationStatus, sendBetTime, isShow }) => {
    const { t } = useTranslation();
    const status = confirmationStatus?.status;
    let msg = '';
    let showTime = true;
    if (status === 'rejected') {
        msg = t('LB_BS_CONFIRMATION_STATUS_MSG2');
    } else if (status === 'accepted') {
        msg = t('LB_BS_CONFIRMATION_STATUS_MSG1');
    } else {
        if (confirmationStatus.accepted > 0) {
            msg = t('LB_BS_CONFIRMATION_STATUS_MSG3');
        } else {
            msg = t('LB_BS_CONFIRMATION_STATUS_MSG4');
            showTime = false;
        }
    }
    return (
        <>
            {isShow && (
                <div className="confirmation-header">
                    <div className={`icon-${status}`}></div>
                    <div className="fontWB">{msg}</div>
                    {showTime && (
                        <div>
                            {t('LB_BS_LOGIN_LAST_LOGIN_INFO_TIME')}: {sendBetTime}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export const BetLineConfirmationHeader = ({ status, code, isShow, txNo, isCounterOffer, resultMsg, resultMsgChi }) => {
    const { t, i18n } = useTranslation();
    const bsConfig = GetBetslipConfig();
    let msg = '';
    if (status == 'accepted') {
        msg = t('LB_BS_ACCEPTED');
    } else {
        if (i18n.language == 'en') {
            msg = resultMsg;
        } else {
            msg = resultMsgChi;
        }
    }

    if ((msg == null || msg == '') && status != 'accepted') {
        msg = t('LB_BS_STATUS_UNKNOWN');
    }

    if (isCounterOffer) {
        if (i18n.language == 'en') {
            msg = resultMsg;
        } else {
            msg = resultMsgChi;
        }
    }

    return (
        <>
            {isShow && (
                <div className="betline-confirmation-header">
                    <div className={`icon-${status} icon-s`}></div>
                    <div className={`text-${status}`}>
                        <span dangerouslySetInnerHTML={{ __html: msg }}></span>
                        <span>{status === 'accepted' ? ` (${txNo})` : ''}</span>
                    </div>
                </div>
            )}
        </>
    );
};

export const BSPanelConfirmationTitle = ({ printContent, onBeforePrint, isShow, confirmationStatus }) => {
    const { t } = useTranslation();

    return (
        <>
            {isShow && (
                <div className="confirmation-title">
                    <div className="confirmation-title-l">
                        {confirmationStatus.accepted > 0 ? (
                            <div className="df">
                                <div className="icon-accepted icon-s"></div>
                                <div>{t('LB_BS_ACCEPTED')}:</div>
                                <div>{confirmationStatus.accepted}</div>
                            </div>
                        ) : (
                            <></>
                        )}
                        {confirmationStatus.rejected > 0 && (
                            <div className="df">
                                <div className="icon-rejected icon-s"></div>
                                <div>{t('LB_BS_REJECTED')}:</div>
                                <div>{confirmationStatus.rejected}</div>
                            </div>
                        )}
                        {confirmationStatus.unknown > 0 && (
                            <div className="df">
                                <div className="icon-unknown icon-s"></div>
                                <div>{t('LB_BS_UNKNOWN')}:</div>
                                <div>{confirmationStatus.unknown}</div>
                            </div>
                        )}
                    </div>
                    <div className="confirmation-title-r">
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <div className="confirmation-printBtn">
                                        <div className="icon-print-black" />
                                        <div>{t('LB_PRINT')}</div>
                                    </div>
                                );
                            }}
                            bodyClass="print-content"
                            documentTitle={document.title}
                            onBeforePrint={onBeforePrint}
                            pageStyle={'size: A4; margin: 15mm;'}
                            content={() => printContent}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export const PanelAlupTemplate = ({
    panelAllupObj,
    panelAllupData,
    setPanelAllupData,
    resetPanelAllupFn,
    cashoutIP = ''
}) => {
    const { i18n, t } = useTranslation();
    const FBconfig = GetFootballConfig();
    const [prevIdLength, setPrevIdLength] = useState(0);
    const { addToBetSlip, betLines } = useBetSlip();
    const allupLen = panelAllupObj?.uniqueMatchIdList?.length;
    const { isMobileSize } = useWindowSize();

    useEffect(() => {
        let newPanelAllupData = { ...panelAllupData };
        if (allupLen < 2) {
            newPanelAllupData = {
                unitBet:
                    parseInt(
                        getPersonalSettings(panelAllupObj?.allupType === 'HR' ? 'racing' : 'football', 'ALUP'),
                        10
                    ) || 10,
                noOfComb: '-',
                dividend: '-',
                netReturn: '-',
                alupFormula: ''
            };
        } else if (allupLen >= 2) {
            let len = allupLen;
            if (prevIdLength !== len || !panelAllupData.alupFormula) {
                newPanelAllupData.alupFormula = FBconfig.AllupFormula[len][0];
            }
        }
        let newObj = {};
        if (panelAllupData.alupFormula) {
            newObj = pAlupCalcFn(panelAllupObj, newPanelAllupData);
        }
        if (prevIdLength !== allupLen && allupLen > 1) {
            newObj = pAlupCalcFn(panelAllupObj, newPanelAllupData);
            newPanelAllupData.unitBet = getMinBet(
                parseInt(getPersonalSettings(panelAllupObj?.allupType === 'HR' ? 'racing' : 'football', 'ALUP'), 10) ||
                10
            );
        }
        setPrevIdLength(allupLen);
        setPanelAllupData((pre) => {
            return {
                ...newPanelAllupData,
                ...newObj
            };
        });
    }, [panelAllupData.alupFormula, panelAllupObj]);

    const changeSelectFormula = (data) => {
        const newObj = pAlupCalcFn(panelAllupObj, {
            ...panelAllupData,
            alupFormula: data
        });
        const unitBet = getMinBet(
            panelAllupData.unitBet
        );
        setPanelAllupData({
            ...panelAllupData,
            alupFormula: data,
            unitBet,
            ...newObj
        });
    };

    const pAlupCalcFn = (panelAllupObj, newPanelAllupData) => {
        if (allupLen < 2) {
            return;
        }
        if (panelAllupObj?.allupType === 'FB') {
            return fbAlupCalc(panelAllupObj, newPanelAllupData);
        }
        if (panelAllupObj?.allupType === 'HR') {
            return hrAlupCalc(panelAllupObj, newPanelAllupData);
        }
    };
    const hrAlupCalc = (newPanelAllupObj, newPanelAllupData) => {
        let noSelArr = [];
        let maxOddsArr = [];
        newPanelAllupObj.allupCheckList.map((item) => {
            noSelArr.push(item.noOfBets);
            maxOddsArr.push(1);
        });
        return fbCalMultiSel(noSelArr, maxOddsArr, newPanelAllupData);
    };
    const addAlupToSlip = () => {
        try {
            const isDelOldBetline = Cookies.getData('AlupSetting') === '2' ? true : false;
            const needDelBetList = [];
            if (panelAllupObj?.allupType === 'FB') {
                const checkedLinesObJ = {};
                const betList = [];
                const matchIdSortList = [];
                let isInplayDelay = false;
                let allUpId = 'ALUP' + panelAllupData.alupFormula;
                panelAllupObj.allupCheckList.forEach((item) => {
                    const newObj = item.combs[0];
                    const { id, matchId, oddsVal, betType, currentPool } = newObj;
                    newObj.raceno = item.raceno || '';
                    if (checkedLinesObJ[matchId]) {
                        checkedLinesObJ[matchId].combs.push(newObj);
                    } else {
                        checkedLinesObJ[matchId] = {
                            combs: [newObj],
                            matchId: matchId,
                            betType: betType,
                            specialLine: betType === 'MSP' ? currentPool : null
                        };
                        matchIdSortList.push(matchId);
                    }
                    if (item.inplayDelay == true) {
                        isInplayDelay = true;
                    }
                    if (isDelOldBetline) {
                        needDelBetList.push(id);
                    }
                });
                Object.keys(checkedLinesObJ)
                    .forEach((key) => {
                        let id = '';
                        let betObj = { ...checkedLinesObJ[key] };
                        betObj.combs
                            .sort((a, b) =>{
                            if(window.fbConfig['HandicapPools'].includes(betObj.betType)){
                                if(a.combId != b.combId) return  a.combId - b.combId;
                                const aMain = a.currentPool.lines.find(i => i.lineId == a.lineId).main
                                const bMain = b.currentPool.lines.find(i => i.lineId == b.lineId).main

                                if(aMain && !bMain){
                                    return -1
                                }else if(!aMain && bMain ){
                                    return 1
                                }
                                return a.oddsVal - b.oddsVal
                            }
                                if(a.poolId > b.poolId){
                                    return 1
                                }else if(a.poolId < b.poolId ) {
                                    return -1
                                }
                               return a.combId - b.combId
                            })
                            .forEach((item) => {
                                id += item.id;
                            });
                        if (panelAllupObj.allupGroup === 2) {
                            betObj.combs.sort((a, b) => {
                                return a.raceno != b.raceno ? a.raceno - b.raceno : a.oddsVal - b.oddsVal;
                            });
                        }
                        let matchInfo = panelAllupObj.allupCheckList?.find(
                            (item) => item.matchId === checkedLinesObJ[key].matchId
                        );
                        betObj.prod = 'FB';
                        betObj.betType = checkedLinesObJ[key].betType;
                        if (matchInfo?.instNo) {
                            betObj.instNo = matchInfo?.instNo;
                        }
                        betObj.id = id;
                        let frontEndId = matchInfo?.frontEndId;
                        betObj = {
                            ...betObj,
                            frontEndId,
                            tournamentNameCH: matchInfo?.tournamentNameCH,
                            tournamentNameEN: matchInfo?.tournamentNameEN,
                            tournamentCode: matchInfo?.tournamentCode,
                            matchTeamEN: matchInfo?.matchTeamEN,
                            matchTeamCH: matchInfo?.matchTeamCH
                        };
                        if (matchInfo?.matchInfo) {
                            betObj.matchInfo=matchInfo.matchInfo;
                        }else if(matchInfo?.tournInfo){
                            betObj.tournInfo=matchInfo.tournInfo;
                        }
                        allUpId += id;
                        betList.push(betObj);
                    });
                if (betLines.find((item) => item.id === allUpId)) {
                    window.alert(t('LB_BS_ADDTOSLIP_FB_ERR_MSG1'));
                    return;
                }
                betList.sort((a, b) => {
                    return matchIdSortList.indexOf(a.matchId) - matchIdSortList.indexOf(b.matchId);
                });
                const AllUpData = {
                    prod: 'FB',
                    betType: 'ALUP',
                    id: allUpId,
                    unitBet: panelAllupData.unitBet,
                    noOfComb: panelAllupData.noOfComb,
                    betList,
                    dividend: panelAllupData.dividend,
                    selectedFormula: panelAllupData.alupFormula,
                    inplayDelay: isInplayDelay
                };
                let successAdd = addToBetSlip([AllUpData], needDelBetList).acceptResult;
                resetPanelAllupFn && successAdd && resetPanelAllupFn();
                WATrackerTrackClickEvent('betslip-fb-addbet-01');
                glassboxCustomEvent('Betslip_Add', 'Football_All_Up');
            }
            if (panelAllupObj?.allupType === 'HR') {
                const betList = [];
                const selectedList = panelAllupObj.allupCheckList;
                selectedList.map((item) => {
                    let newObj = {
                        raceNo: item.raceno,
                        betType: item.betType,
                        subType: item.subType || '',
                        combs: item.combs
                    };
                    if (item.bankers) {
                        newObj.bankers = item.bankers;
                    } else {
                        newObj.bankers = [[], [], []];
                    }
                    if (item.betType === 'CWA') {
                        newObj.combs = item.betList;
                    }
                    betList.push(newObj);
                    if (isDelOldBetline) {
                        needDelBetList.push(item.id);
                    }
                });
                betList.sort((a, b) => {
                    return a.raceNo - b.raceNo;
                });
                const AllUpData = {
                    prod: 'HR',
                    betType: 'CROSS_ALUP',
                    id: Date.now().toString(),
                    unitBet: panelAllupData.unitBet,
                    noOfBets: panelAllupData.noOfComb,
                    allupFormula: panelAllupData.alupFormula,
                    isRandom: false,
                    isFlexi: false,
                    useFlexiUi: true,
                    poolid: 'CROSS_ALUP',
                    venueid: selectedList[0].venueid,
                    dayid: selectedList[0].dayid,
                    meetingId: selectedList[0].meetingId,
                    betList
                };
                let successAdd = addToBetSlip([AllUpData], needDelBetList).acceptResult;
                resetPanelAllupFn && successAdd && resetPanelAllupFn();
                WATrackerTrackClickEvent('betslip-hr-addbet-01');
                glassboxCustomEvent('Betslip_Add', 'Racing_All_Up');
            }
        } catch (err) {
            console.error(err);
        }
    };

    const onFocusBetbox = (e) => {
        // e.target.value = '';
        if (isMobileSize) {
            e.preventDefault();
            e.target.setAttribute('data-init', 1);
            e.target.removeAttribute('maxLength')
            e.target.setSelectionRange(10, 10);
            setTimeout(function () {
                e.target.setSelectionRange(10, 10);
            });
        }

    };

    const onInputBet = (e) => {
        let value = e.target.value;
        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
            if (e.nativeEvent.data != null) {
                value = e.nativeEvent.data;
            }
            e.target.setAttribute('data-init', 0);
            e.target.setAttribute('maxLength', 10)
        }
        if (/\D/g.test(value)) {
            value = value.replace(/\D/g, '');
        }
        const newData = pAlupCalcFn(panelAllupObj, {...panelAllupData, unitBet: value})
        setPanelAllupData({
            ...panelAllupData,
            unitBet: value,
            ...newData
        });
    };

    const onBlurBet = () => {
        const minBet = getMinBet(10);
        if (panelAllupData.unitBet <= minBet) {
            setPanelAllupData({
                ...panelAllupData,
                unitBet: minBet
            });
        } else {
            setPanelAllupData({
                ...panelAllupData,
                unitBet: panelAllupData.unitBet
            });
        }
    };

    const getMinBet = (val, unitBet, noOfComb) => {
        let minBet = val || 10;
        // if (panelAllupObj?.allupType === 'FB') {
        //     const { AllPoolsMinBet, HandicapOnlyPools } = FBconfig;
        //     let noComb = 1;
        //     if (noOfComb != '-') {
        //         noComb = noOfComb;
        //     }
            // const hasHDC = panelAllupObj?.allupCheckList.filter(x => HandicapOnlyPools.includes(x.betType)).length > 0;
            // if (noComb === 1 && hasHDC) {
            //     minBet = 200;
            // } else if (formatNumber(noComb * unitBet, 1, false, false, true) < 200 && hasHDC) {
            //     minBet = Math.ceil(200 / noComb);
            // }
        // }
        return minBet;
    };

    const clearPanelAlup = () => {
        setPanelAllupData({
            unitBet: 10,
            noOfComb: '-',
            dividend: '-',
            netReturn: '-',
            alupFormula: ''
        });
        resetPanelAllupFn && resetPanelAllupFn();
    };

    const betTotalFn = () => {
        const { noOfComb = '-' } = panelAllupData;
        if (noOfComb != '-') {
            return textFormatAmountFn(noOfComb * (panelAllupData.unitBet - 0));
        }
        return noOfComb;
    };

    const allupFormulaItem = () => {
        let itemList = [];
        let alupCnt = panelAllupData.alupFormula != '' ? parseInt(panelAllupData.alupFormula.split('x')[0]) : 0;
        if (alupCnt <= 1) {
            return [];
        }

        let allupFormulaList = FBconfig.AllupFormula[alupCnt];

        allupFormulaList.map((item) => {
            itemList.push(
                <Dropdown.Item
                    eventKey={'formula-value-' + item}
                    key={`formula-value-` + item}
                    disabled={false}
                    align="start"
                    variant={item}
                    title={item}
                    value={item}
                    onClick={() => changeSelectFormula(item)}
                >
                    {item}
                </Dropdown.Item>
            );
        });

        return itemList;
    };
    const nodeRef = useRef(null);
    return (
        <CSSTransition in={allupLen > 0} nodeRef={nodeRef} timeout={1000} classNames="alert" unmountOnExit>
            <div className="allup-add-container" ref={nodeRef}>
                <div className="allup-container">
                    <div className="formula">{t('LB_BS_ALLUP_FORMULA')}</div>
                    {allupLen >= 2 ? (
                        <>
                            <div>
                                <DropdownButton
                                    as={ButtonGroup}
                                    key={`panelAllupFormula`}
                                    id={`panelAllupFormula`}
                                    align="start"
                                    variant={panelAllupData.alupFormula}
                                    title={
                                        <>
                                            <div>{panelAllupData.alupFormula}</div>
                                            <ArrowIcon direction="down" size={24} />
                                        </>
                                    }
                                >
                                    {allupFormulaItem()}
                                </DropdownButton>
                            </div>
                            <div className="unitbet-input">
                                <span>$</span>
                                <input
                                    maxLength="10"
                                    type="text"
                                    inputMode="numeric"
                                    value={textFormatAmountFn(panelAllupData.unitBet, 0, false)}
                                    onFocus={onFocusBetbox}
                                    onInput={onInputBet}
                                    onBlur={onBlurBet}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="disFormula"></div>
                            <div className="disFormula"></div>
                        </>
                    )}
                </div>
                <div className="add-slip">
                    <div>
                        <div>{t('LB_BS_ALUP_BET_TOTAL')}:</div>
                        <div>${betTotalFn()}</div>
                    </div>
                    <div style={{ visibility: panelAllupObj?.allupType === 'FB' ? 'visible' : 'hidden' }}>
                        <div>{t('LB_FB_MAXIMUM_DIVIDEND')}:</div>
                        <div>${textFormatAmountFn(panelAllupData.dividend, 2)}</div>
                    </div>
                    <div className={`betSlipBottomBtnArea ${cashoutIP}`}>
                        <div>
                            <div className={'alupBtn alupBack'} onClick={clearPanelAlup}>
                                {t('LB_BETSLIP_PREVIEWBACK')}
                            </div>
                        </div>
                        <div>
                            <div
                                className={`alupBtn send alupSend ${allupLen <= 1 ? 'alupDimm' : ''}`}
                                onClick={addAlupToSlip}
                            >
                                {t('LB_ADDTOSLIP')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export const isParentNode = (parentClassName, child) => {
    if (!child) return false;
    const endNode = document.getElementById('root');
    let currentNode = child;

    while (currentNode !== endNode) {
        if (currentNode?.className?.split(' ')?.find((i) => parentClassName?.includes(i))) {
            return true;
        }
        if (!currentNode?.parentNode) return false;
        currentNode = currentNode.parentNode;
    }
    return false;
};

export const ResendDoneDialog = ({ onClickConfirm }) => {
    const { t } = useTranslation();
    const title = location.host;
    const handleClickConfirm = (close) => {
        close();
        onClickConfirm();
    };
    const handleClickCancel = (close) => {
        close();
    };

    return (
        <Popup
            trigger={(open) => <button className="resendDone">{t('LB_BETSLIP_DONE')}</button>}
            position="center center"
            modal
            closeOnDocumentClick={false}
            className="resendDoneDialog"
        >
            {(close) => (
                <div className="resendDoneDialogContainer">
                    <div className="title">{title}</div>
                    <div className="content">{t('LB_BS_RESENDBET_DONE_MSG')}</div>
                    <div className="buttonContainer">
                        <div onClick={() => handleClickCancel(close)} className="cancel">
                            {t('LB_BS_LOGIN_CANCEL')}
                        </div>
                        <div onClick={() => handleClickConfirm(close)} className="confirm">
                            {t('LB_BS_LOGIN_CONFIRM')}
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
};
