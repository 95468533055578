import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from './Pagination';
import MergedPoolRemarks from '../Common/MergedPoolRemarks';
import { RacingContext } from '../../Home/RacingPage';
import { useQuickBet } from './QuickBetHook';
import { Scrollbars } from 'react-custom-scrollbars-2';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import {isStartSellpmPool } from './Common';
import { clipboardIndicatorStyle } from './ClipboardCommon';

const IWNOddsTable = ({ msg, legList, enableQuickBet, isStartSell }) => {
    const { t } = useTranslation();
    const context = useContext(RacingContext);
    const mtg = context.content.meeting;
    const race1No = legList ? legList[0] : context.content.raceNo;
    const race2No = legList ? legList[1] : context.content.raceNo;
    let preSuffix = context.content.isPresales ? 'Pre' : '';
    const pmPool = mtg.pmPools.filter(
        (data) => data.leg.races[0] == race1No && data.oddsType == context.content.page + preSuffix
    )[0];
    const race1 = mtg.races.filter((data) => data.no == race1No)[0];
    const race2 = mtg.races.filter((data) => data.no == race2No)[0];
    const fieldSize1 = race1.wageringFieldSize;
    const fieldSize2 = race2.wageringFieldSize;

    const tableSize = ['DBL'].includes(context.content.page) && fieldSize1 > 14 ? 17 : 14;
    const tableRef = useRef();
    const containerRef = useRef();
    const [pageNumber, setPageNumber] = useState(1);
    const [showTable, setShowTable] = useState(true);
    const { handleClickCombOdds } = useQuickBet();

    useEffect(() => {
        setPageNumber(1);
    }, [context.content.raceNo]);

    useEffect(() => {
        if ( tableRef?.current) onChangeTableDisplay(true);
        setShowTable(true)
    }, [pmPool?.oddsNodes]);

    const handleClickOdds = (odds) => {
        //const startSell = pmPool.status === 'START_SELL';
        const startSell = isStartSellpmPool(pmPool);
        if (startSell) {
            handleClickCombOdds(odds, context.content.page);
        }
    };

    const onChangeTableDisplay = (defaultOpen) => {
        const height = tableRef?.current?.clientHeight + 50;
        if(defaultOpen)  {
            containerRef.current.style.height = height + 'px';
            return
        }
        if (!showTable) {
            containerRef.current.style.height = height + 'px';
        } else {
            containerRef.current.style.height = '40px';
        }
        setShowTable(!showTable);
    };

    const checkScratch = (top, left, odds) => {
        const data = race1.runners.find((item) => parseInt(item.no) === top);
        const data2 = race2.runners.find((item) => parseInt(item.no) === left);
        const s1 = data?.status.toUpperCase().indexOf('SCRATCHED') >= 0;
        const s2 = data2?.status.toUpperCase().indexOf('SCRATCHED') >= 0;
        const r1 = ['RESERVED', 'STANDBY'].includes(data?.status.toUpperCase());
        const r2 = ['RESERVED', 'STANDBY'].includes(data2?.status.toUpperCase());
        const sr1 = s1 && r1;
        const sr2 = s2 && r2;
        const n1 = !s1 && !r1;
        const n2 = !s2 && !r2;

        if (!data || !data2) return;
        if (odds === 'SCR' || (n1 && s2) || (n2 && s1) || (n1 && sr2) || (n2 && sr1)
            || (s1 && sr2) || (s2 && sr1) || (s1 && s2) || (sr1 && sr2)) {
            return context.content.page=="DBL" ?  t('LB_RC_SCR') : t('LB_RC_SCR_S');
        }
        if (r1 || r2)
            return '---';
    };

    const combDelimiter = () => {
        switch (context.content.page) {
            case 'DBL':
                return '/';
            case 'IWN':
                return '#';
            default:
                return ',';
        }
    };

    const findOdds = (top, left) => {
        if (pmPool.oddsNodes.length === 0) return;
        let data = pmPool.oddsNodes.find((item) => {
            const [iTop, iLeft] = item.combString.split(combDelimiter());
            return Number(iTop) === top && Number(iLeft) === left;
        });

        return data || {};
    };

    const oddsTableProps = { tableSize, t, pageNumber, context, pmPool, fieldSize1, fieldSize2, findOdds, checkScratch, isStartSell, enableQuickBet, handleClickOdds}

    context.racingFuncs.copyClipboardLeftOddsTable = () => { 
        return clipboardTableAll({...oddsTableProps});
    }

    return <div className={'iwn-odds-table-container'} ref={containerRef}>
        <div
            className={`odds-table-display-switch ${showTable ? 'show-table' : ''}`}
            onClick={()=> onChangeTableDisplay()}
        >
            {t('LB_ODDS')}
            <div>
                <ArrowIcon direction='down' change={showTable} changeDirection='up' />
            </div>
        </div>
        <div ref={tableRef}>
            <div className="iwn-odds-slide-container">
                <Scrollbars
                    autoHeight
                    autoHeightMin={50}
                    autoHeightMax={1500}
                >
                    <div className="iwn-odds-table">
                        <TableHeader {...oddsTableProps}/>
                        <OddsTable {...oddsTableProps}/>
                    </div>
                </Scrollbars>
            </div>
            <Pagination onChange={setPageNumber} pageNum={pageNumber} total={Math.ceil(fieldSize1 / tableSize)} />
            {/* <LeftNumber {...oddsTableProps}/> */}
            <MergedPoolRemarks betType={context.content.page} />
            {msg}
        </div>
    </div>
};

export default IWNOddsTable;

const TableHeader = ({tableSize,t, pageNumber, context}) => {
    let header = [];
    for (let i = 0; i <= tableSize; i++) {
        const num = i + tableSize * (pageNumber - 1);
        if (i === 0)
            header.push(
                <div className="iwn-odds-table-title" key={'table-header' + i}>
                    <div>
                        {t(`LB_RC_ODDS_IWNTABLE_1ST_${context.content.page}`)}&nbsp;
                        {t(`LB_RC_ODDS_IWNTABLE_SUF_${context.content.page}`)}
                    </div>
                    <div className="left-number">
                        {t(`LB_RC_ODDS_IWNTABLE_2ND_${context.content.page}`)}&nbsp;
                        {t(`LB_RC_ODDS_IWNTABLE_SUF_${context.content.page}`)}
                    </div>
                </div>
            );
        else {
            header.push(<div key={'table-header' + i}>{num<=34 ? num : ''}</div>);
        }
    }

    return <div className="iwn-odds-table-header">{header}</div>;
};

const Row = ({ index, className, tableSize, pageNumber, context, pmPool, fieldSize1, fieldSize2, findOdds, checkScratch, isStartSell, enableQuickBet, handleClickOdds }) => {
    const row = [];
    row.push(
        <div className="left-number" key={'left-number' + index}>
            {index}
        </div>
    );
    for (let i = 1; i <= tableSize; i++) {
        const num = i + tableSize * (pageNumber - 1);

        if (
            !pmPool ||
            (num === index && !['DBL'].includes(context.content.page)) ||
            pmPool.oddsNodes.length === 0 ||
            num > fieldSize1 ||
            index > fieldSize2
        ) {
            const scratched = (num != index || ['DBL'].includes(context.content.page)) ? checkScratch(num, index, "") : null;
            row.push(<div key={`cell-${index}-${i}`}>{scratched}</div>);
        }
        else {
            if (pmPool.status.indexOf('REFUND') >= 0) {
                row.push(<div key={`cell-${index}-${i}`} id={`qb_${context.content.page}_${i}_${index}`} className='disabled'>---</div>);
                continue;
            }
            const oddsInfo = findOdds(num, index) || {};
            const scratched = checkScratch(num, index, oddsInfo.oddsValue);
            if (!scratched && (oddsInfo.oddsValue == null || oddsInfo.oddsValue == '')) {
                row.push(<div key={`cell-${index}-${i}`}></div>);
                continue;
            }
            const value = scratched || oddsInfo?.oddsValue;
            const isPresales = context?.content?.isPresales
            const hot = !scratched
                ? oddsInfo?.hotFavourite && !isNaN(Number(oddsInfo?.oddsValue))
                    ? 'favourite'
                    : isPresales ? ''
                        :
                        (!['DBL'].includes(context.content.page) && oddsInfo?.oddsDropValue >= 50)
                        ? 'drop50'
                        : (!['DBL'].includes(context.content.page) && oddsInfo?.oddsDropValue >= 20)
                        ? 'drop20'
                        : ''
                : '';
            row.push(
                <div key={`cell-${index}-${i}`} className={`${hot} ${!isStartSell || scratched ? "disabled" : ''}`}>
                    <span
                        id={`qb_${context.content.page}_${i}_${index}`}
                        className={`${!enableQuickBet || scratched ? '' : 'cp table-odds'}`}
                        onClick={() => enableQuickBet && !scratched && handleClickOdds(oddsInfo)}
                        clickable={enableQuickBet ? 'true' : 'false'}
                    >
                        {value}
                    </span>
                </div>
            );
        }
    }

    return <div className={`iwn-odds-table-row ${className}`}>{row}</div>;
};

const OddsTable = (props) => {
    const {context, fieldSize2, fieldSize1, tableSize} = props
    const table = [];
    let length = 14;
    if (['DBL'].includes(context.content.page)) {
        if (fieldSize2 > 14) length = fieldSize2;
    } else {
        length = fieldSize1 < tableSize ? tableSize : fieldSize1;
    }
    for (let i = 1; i <= length; i++) {
        table.push(<Row index={i} className={i % 2 === 0 ? 'sec' : ''} key={i} {...props}/>);
    }
    return table;
};

const LeftNumber = ({ tableSize, context,fieldSize2, t}) => {
    const items = [];
    let length = 14;
    if (['DBL'].includes(context.content.page)) {
        if (fieldSize2 > 14) length = fieldSize2;
        // else if (fieldSize2 > 14) length = 17;
    } else {
        length = fieldSize2 < tableSize ? tableSize : fieldSize2;
    }
    items.push(
        <div className="left-number-title" key={'leftNumber-title'}>
            <div>
                <div>{t(`LB_RC_ODDS_IWNTABLE_1ST_${context.content.page}`)}&nbsp;
                {t(`LB_RC_ODDS_IWNTABLE_SUF_${context.content.page}`)}
                </div>
                <div className="left-number">
                    {t(`LB_RC_ODDS_IWNTABLE_2ND_${context.content.page}`)}&nbsp;
                    {t(`LB_RC_ODDS_IWNTABLE_SUF_${context.content.page}`)}
                </div>
            </div>
        </div>
    );
    for (let i = 1; i <= length; i++) {
        items.push(
            <div className="left-number" key={'leftNumber-' + i}>
                <div>{i}</div>
            </div>
        );
    }

    return <div className="left-number-box">{items}</div>;
};

const clipboardRow = (index, pageNumber, props) => {
    const { context, tableSize, pmPool, fieldSize1, fieldSize2, findOdds, checkScratch } = props;
    const row = [];
    row.push(`<td style="background-color:#6176a0;color:#ffffff">${index}</td>`);

    for (let i = 1; i <= tableSize; i++) {
        const num = i + tableSize * (pageNumber - 1);
        if (!pmPool ||
            (num === index && !['DBL'].includes(context.content.page)) ||
            pmPool.oddsNodes.length === 0 ||
            num > fieldSize1 ||
            index > fieldSize2
        ) {
            const scratched = (num != index || ['DBL'].includes(context.content.page)) ? checkScratch(num, index, "") : "";
            row.push(`<td>${scratched || ""}</td>`);
        }
        else {
            if (pmPool.status.indexOf('REFUND') >= 0) {
                row.push(`<td>---</td>`);
            }
            else {
                const oddsInfo = findOdds(num, index);
                const scratched = checkScratch(num, index, oddsInfo?.oddsValue);
                const value = scratched || oddsInfo?.oddsValue || "";
                const style =  clipboardIndicatorStyle(context, oddsInfo?.hotFavourite, oddsInfo?.oddsDropValue);
                row.push(`<td style="${style}">${value}</td>`);
            }
        }
    }

    return `<tr>${row.join("")}</tr>`;
};

const clipboardTable = (pageNum, props) => {
    const { t, context, tableSize, fieldSize2 } = props;
    let table = [];

    let header = [];
    for (let i = 0; i <= tableSize; i++) {
        const num = i + (pageNum-1) * 14;
        if (i === 0) {
            header.push(`<td style="background-color:#122c68;color:#ffffff">${t(`LB_RC_ODDS_IWNTABLE_1ST_${context.content.page}`)} ${t(`LB_RC_ODDS_IWNTABLE_SUF_${context.content.page}`)}</td>`);
        }
        else {
            header.push(`<td rowspan="2" style="background-color:#122c68;color:#ffffff">${num<=34 ? num : ''}</td>`);
        }
    }
    table.push(`<tr>${header.join("")}</tr>`);
    table.push(`<tr><td style="background-color:#6176a0;color:#ffffff">${t(`LB_RC_ODDS_IWNTABLE_2ND_${context.content.page}`)} ${t(`LB_RC_ODDS_IWNTABLE_SUF_${context.content.page}`)}</td></tr>`);

    for (let i=1; i<=fieldSize2; i++ ) {
        table.push(clipboardRow(i, pageNum, props));
    }

    return `<table>${table.join("")}</table>`;
}

const clipboardTableAll = (props) => {
    const { t, context, tableSize, fieldSize1 } = props;
    let table = [];

    let total = Math.ceil(fieldSize1 / tableSize);
    for ( let i = 1; i<=total; i++ ) {
        table.push(clipboardTable(i, props));
    }

    return table.join("");
}